
.App {
  text-align: center;
  background-image: url('./assets/flower-wall.jpg');
  max-width:100%;
  min-width:100%
}

.navBar{
  background: #FFFFFF;
  z-index: 100;
  margin: 0;
  padding: 2px 0 0 12px;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
 
}

.messengerBox{
  position:fixed;
  bottom: 0;
  right:0
}
li{
  list-style-type: none;
}

.MuiTypography-root{
font-family: 'Sawarabi Mincho', serif ;  
}

.bg{
z-index:-1;opacity: .1;
  /* Full height 
*/
  height: 100%;
position:fixed;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  width:100%;
  height:100%; 
}
.centerText{
  text-align: center;
  scroll-behavior: smooth;
}


.candem{
  text-align: center;
  background: url('./assets/candmsmaller.jpg') no-repeat center center ;

}

.border{
  background: url('./assets/black-border.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.palmBacked{
  background-image: url('./assets/palm.jpg');
  max-width:100%;
 
  max-height:100%;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  object-fit: contain
}

/*prussian blue:#263A5B
morning blue:#89A09E
rich black:#12403C
old mauve:#682747
white:#FFFFFF */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
